import React from 'react'
import TOP9 from './Img/bombas de calor/TOP+9 8,7KW 50HZ.jpg'
import TOP23 from './Img/bombas de calor/TOP-+23.jpg'

function BombasTop() {
  return (
    <div className="row p-2 ms-5 me-5 border-0 principalrow">
            <h5 className="card-title text-center robot pb-3 ">Bombas de calor ASTRAL - TOP +</h5>
            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                <div className="card">
                    <img src={TOP9} className="card-img-top ps-5 pe-5" alt="..." />
                    <div className="card-body body-robots">
                        <h5 className="card-title text-center robot">TOP+6 - 220V</h5>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Potencia:</div>
                            <div className='col-6'>6,1 kW</div>
                        </div>
                        <div className='row  p-1'>
                            <div className='col-6'>Volúmen máximo:</div>
                            <div className='col-6'>27 m3</div>
                        </div>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Conexión de agua:</div>
                            <div className='col-6'>50mm</div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-6'>Compatible con Wi-fi:</div>
                            <div className='col-6'>SI</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                <div className="card">
                    <img src={TOP9} className="card-img-top ps-5 pe-5" alt="..." />
                    <div className="card-body body-robots">
                        <h5 className="card-title text-center robot">TOP+9</h5>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Potencia:</div>
                            <div className='col-6'>8,7 kW</div>
                        </div>
                         <div className='row  p-1'>
                            <div className='col-6'>Volumen máximo:</div>
                            <div className='col-6'>36 m3</div>
                        </div>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Conexión de agua:</div>
                            <div className='col-6'>50mm</div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-6'>Compatible con Wi-fi:</div>
                            <div className='col-6'>SI</div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                <div className="card">
                    <img src={TOP9} className="card-img-top ps-5 pe-5" alt="..." />
                    <div className="card-body body-robots">
                        <h5 className="card-title text-center robot">TOP+14</h5>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Potencia:</div>
                            <div className='col-6'>14 kW</div>
                        </div>
                        <div className='row  p-1'>
                            <div className='col-6'>Volumen máximo:</div>
                            <div className='col-6'>60 m3</div>
                        </div>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Conexión de agua:</div>
                            <div className='col-6'>50mm</div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-6'>Compatible con Wi-fi:</div>
                            <div className='col-6'>SI</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                <div className="card">
                    <img src={TOP9} className="card-img-top ps-5 pe-5" alt="..." />
                    <div className="card-body body-robots">
                        <h5 className="card-title text-center robot">TOP+19</h5>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Potencia:</div>
                            <div className='col-6'>17,4 kW</div>
                        </div>
                         <div className='row  p-1'>
                            <div className='col-6'>Volumen máximo:</div>
                            <div className='col-6'>80 m3</div>
                        </div>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Conexión de agua:</div>
                            <div className='col-6'>50mm</div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-6'>Compatible con Wi-fi:</div>
                            <div className='col-6'>SI</div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                <div className="card">
                    <img src={TOP23} className="card-img-top ps-5 pe-5" alt="..." />
                    <div className="card-body body-robots">
                        <h5 className="card-title text-center robot">TOP+23</h5>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Potencia:</div>
                            <div className='col-6'>23 kW</div>
                        </div>
                         <div className='row  p-1'>
                            <div className='col-6'>Volumen máximo:</div>
                            <div className='col-6'>100 m3</div>
                        </div>
                        <div className='row bg-secondary bg-opacity-25 p-1'>
                            <div className='col-6'>Conexión de agua:</div>
                            <div className='col-6'>50mm</div>
                        </div>
                        <div className='row p-1'>
                            <div className='col-6'>Compatible con Wi-fi:</div>
                            <div className='col-6'>SI</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
  )
}

export default BombasTop