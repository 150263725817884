import React from 'react'
import amatista from './Img/medianas/Amatista.png';
import amatistaIlustracion from './Img/medianas/Amatista ilustracion.png';
import aguamarina from './Img/medianas/Agua Marina.png';
import aguamarinaIlustracion from './Img/medianas/Agua Marina ilustracion.png';
import turquesa from './Img/medianas/Turquesa.png';
import turquesaIlustracion from './Img/medianas/Turquesa ilustracion.png';
import turmalina from './Img/medianas/Turmalina.png';
import turmalinaIlustracion from './Img/medianas/Turmalina ilustracion.png';
import kriptonita from './Img/medianas/Kriptonita.png';
import kriptonitaIlustracion from './Img/medianas/Kriptonita ilustracion.png';
import agata from './Img/medianas/Agata.png';
import agataIlustracion from './Img/medianas/Agata ilustracion.png';
import gemaazulIlustracion from './Img/medianas/Gema Azul.jpg';
import opaloilustracion from './Img/medianas/Opalo_ilustracion-transformed.png'
import coralilustracion from './Img/medianas/Coral_ilustracion-transformed.png'


function PiletasMedianas() {
  return (
    <>
      <br></br><br></br><br></br>

      <div className='mt-5 w-100'>
        <div className='row m-1'>
          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Amatista</h4>
              <img src={amatista} className='card-img-top px-3' alt='Circón' />
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 6,50 m</div>
                  <div className='card border-0 ps-2'>Ancho: 3,10 m</div>
                  <div className='card ps-2'>Profundidad: 1,45 a 1,60 m</div>
                  <div className='card border-0 ps-2'>Volumen: 27.000 lt</div>
                </div>
              </div>
              <br />
              <img src={amatistaIlustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Agua Marina</h4>
              <img src={aguamarina} className='card-img-top px-3' alt='Circón' />
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 7,30 m</div>
                  <div className='card border-0 ps-2'>Ancho: 3,50 m</div>
                  <div className='card ps-2'>Profundidad: 1,45 a 1,60 m</div>
                  <div className='card border-0 ps-2'>Volumen: 35.000 lt</div>
                </div>
              </div>
              <br />
              <img src={aguamarinaIlustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Turquesa</h4>
              <img src={turquesa} className='card-img-top px-3' alt='Circón' />
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 6,50 m</div>
                  <div className='card border-0 ps-2'>Ancho: 3,10 m</div>
                  <div className='card ps-2'>Profundidad: 1,30 a 1,60 m</div>
                  <div className='card border-0 ps-2'>Volumen: 29.000 lt</div>
                </div>
              </div>
              <br />
              <img src={turquesaIlustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>


        </div>
      </div>





      <div className='mt-5 w-100'>
        <div className='row m-1'>
          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Kriptonita</h4>
              <img src={kriptonita} className='card-img-top px-3' alt='Circón' />
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 6,0 m</div>
                  <div className='card border-0 ps-2'>Ancho: 2,90 m</div>
                  <div className='card ps-2'>Profundidad: 1,3 m</div>
                  <div className='card border-0 ps-2'>Volumen: 17.000 lt</div>
                </div>
              </div>
              <br />
              <img src={kriptonitaIlustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Turmalina</h4>
              <img src={turmalina} className='card-img-top px-3' alt='Circón' />
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 7,30 m</div>
                  <div className='card border-0 ps-2'>Ancho: 3,50 m</div>
                  <div className='card ps-2'>Profundidad: 1,40 a 1,60 m</div>
                  <div className='card border-0 ps-2'>Volumen: 35.000 lt</div>
                </div>
              </div>
              <br />
              <img src={turmalinaIlustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Ágata</h4>
              <img src={agata} className='card-img-top px-3' alt='Circón' />
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 7,40 m</div>
                  <div className='card border-0 ps-2'>Ancho: 3,50 m</div>
                  <div className='card ps-2'>Profundidad: 1,40 a 1,60 m</div>
                  <div className='card border-0 ps-2'>Volumen: 35.000 lt</div>
                </div>
              </div>
              <br />
              <img src={agataIlustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>
          {/*
          <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Gema Azúl</h4> 
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 7,30 m</div>
                <div className='card border-0 ps-2'>Ancho: 3,55 m</div>
                <div className='card ps-2'>Profundidad: 1,40 a 1,60 m</div>
                <div className='card border-0 ps-2'>Volumen: 35.000 lt</div>
              </div>
            </div>
            <br />
            <img src={gemaazulIlustracion} className='card-img-top' alt='Gema Azúl' />
          </div>
        </div>
          */}
        </div>
      </div>
      <div className='mt-5 w-100'>
        <div className='row m-1'>
          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Gema Azul</h4>
              {/*<img src={kriptonita} className='card-img-top px-3' alt='Circón' /> */}
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 7,30 m</div>
                  <div className='card border-0 ps-2'>Ancho: 3,50 m</div>
                  <div className='card ps-2'>Profundidad: 1,40 a 1.60 m</div>
                  <div className='card border-0 ps-2'>Volumen: 35.000 lt</div>
                </div>
              </div>
              <br />
              <img src={gemaazulIlustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Ópalo</h4>
            {/*<img src={turmalina} className='card-img-top px-3' alt='Circón' /> */}
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 7,30 m</div>
                  <div className='card border-0 ps-2'>Ancho: 3,40 m</div>
                  <div className='card ps-2'>Profundidad: 1,40 a 1,55 m</div>
                  <div className='card border-0 ps-2'>Volumen: 36.000 lt</div>
                </div>
              </div>
              <br />
              <img src={opaloilustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>

          <div className='col-md-4 mb-4'>
            <div className='card mt-3' id='border'>
              <h4 className='card-title text-center pt-2'>Coral</h4>
              {/*<img src={agata} className='card-img-top px-3' alt='Circón' />*/}
              <div className='card-body'>
                <div className='card p-1' id='border'>
                  <h6 className='ps-1 fw-bold'>Medidas</h6>
                  <div className='card ps-2'>Largo: 7,0 m</div>
                  <div className='card border-0 ps-2'>Ancho: 3,10 m</div>
                  <div className='card ps-2'>Profundidad: 1,40 m</div>
                  <div className='card border-0 ps-2'>Volumen: 27.000 lt</div>
                </div>
              </div>
              <br />
              <img src={coralilustracion} className='card-img-top' alt='Circón' />
            </div>
          </div>
        </div>
      </div>



    </>
  )
}

export default PiletasMedianas