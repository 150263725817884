import React, { useEffect, useState } from 'react'
import instantaneo from './Img/productos/CloroInstantaneo.jpg';
import granulado from './Img/productos/CloroGranulado.jpg';
import pastillas from './Img/productos/pastillas.jpg';
import natabio from './Img/productos/natabio.jpg';
import clarificador from './Img/productos/clarificador.jpg';
import alguicida from './Img/productos/alguicida.jpg';
import hongos from './Img/productos/tecnocorHongos.jpg';
import insectos from './Img/productos/insectos.jpg';
import lona from './Img/productos/Lona.jpg';


function MobilePageProductos() {
    return (
      <>
        <br /><br /><br /><br />
        <div className='text-center '>
        <h2 className='textoproductos'>Tenemos todo lo necesario para mantener en perfectas condiciones tu piscina</h2>
        <a href='calculoproductos' className='custom-link h2'>¿Cuánto producto necesito para mi pileta? ¡Descubrilo!</a>
      </div>
        <div id="carouselExampleControls" className="carousel carousel-dark slide w-100" data-bs-ride="carousel">
          <div className="carousel-inner">
            <div className="carousel-item active">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={instantaneo} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Cloro instantáneo</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={granulado} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Cloro granulado</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={pastillas} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Pastillas multiacción</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={natabio} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Natabio</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={clarificador} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Clarificador</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={alguicida} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Alguicida</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={lona} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Pileta de lona</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={hongos} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Producto para hongos</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="carousel-item">
              <div className="row card-wrapper d-flex justify-content-around w-100 mx-auto">
                <div className='col-12'>
                  <div className="card">
                    <img src={insectos} className="card-img-top" alt="producto" />
                    <div className="card-body">
                      <h6 className="card-title text-center productomobile">Producto para insectos</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Agrega más 'carousel-item' para cada grupo de productos */}
          </div>
          <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </>
    );
  }
  
  export default MobilePageProductos;