import React, { useState, useEffect } from 'react';

const CalculadoraProductos = () => {
    const [producto, setProducto] = useState('');
    const [litros, setLitros] = useState('');
    const [temporada, setTemporada] = useState('verano'); // Estado para controlar la temporada
    const [result, setResult] = useState(null);

    const calcular = () => {
        if (producto === 'Alguicida' || producto === 'Clarificador') {
            calcularAlguicidaOClarificador();
        } else if (producto === 'Cloro instantaneo' || producto === 'Cloro granulado') {
            calcularCloro();
        } else if (producto === 'Natabio') {
            calcularNatabio();
        } else if (producto === 'Pastillas multiaccion 200gr') {
            calcularPastillas200gr();
        } else if (producto === 'Pastillas multiaccion 50gr') {
            calcularPastillas50gr();
        }
    };

    useEffect(() => {
        if (producto && litros) {
            calcular();
        }
    }, [producto, litros, temporada]);

    const handleSubmit = (event) => {
        event.preventDefault(); // Evita el envío del formulario
        calcular();
    };

    const calcularAlguicidaOClarificador = () => {
        const baseLitros = 40000;
        let dosisPorSemana, dosisPorMes, unidadSemana, unidadMes;

        if (producto === 'Alguicida') {
            dosisPorSemana = (litros / baseLitros) * 400;
            dosisPorMes = dosisPorSemana * 4;
        } else if (producto === 'Clarificador') {
            dosisPorSemana = (litros / baseLitros) * 200;
            dosisPorMes = dosisPorSemana * 4;
        }

        unidadSemana = dosisPorSemana < 1000 ? 'ml' : 'lt';
        unidadMes = dosisPorMes < 1000 ? 'ml' : 'lt';

        setResult({
            producto,
            dosisPorSemana: parseFloat(dosisPorSemana.toFixed(2)),
            dosisPorMes: formatLitros(dosisPorMes),
            unidadSemana,
            unidadMes,
        });
    };

    const calcularCloro = () => {
        const baseLitros = 40000;
        let dosisPorSemana, dosisPorMes, unidadSemana, unidadMes;

        if (temporada === 'verano') {
            dosisPorSemana = (litros / baseLitros) * 80;
            dosisPorMes = (litros / baseLitros) * 2200;
            unidadSemana = dosisPorSemana < 1000 ? 'gr' : 'kg';
            unidadMes = dosisPorMes < 1000 ? 'gr' : 'kg';
        } else if (temporada === 'invierno') {
            dosisPorSemana = (litros / baseLitros) * 80;
            dosisPorMes = dosisPorSemana * 4;
            unidadSemana = dosisPorSemana < 1000 ? 'gr' : 'kg';
            unidadMes = dosisPorMes < 1000 ? 'gr' : 'kg';
        }

        setResult({
            producto,
            dosisPorSemana: parseFloat(dosisPorSemana.toFixed(2)),
            dosisPorMes: formatLitros(dosisPorMes),
            unidadSemana,
            unidadMes,
        });
    };

    const calcularNatabio = () => {
        const baseLitros = 40000;
        let dosisPorMes, dosisCadaTresMeses, unidadMes;
    
        if (temporada === 'verano') {
            dosisCadaTresMeses = (litros / baseLitros) * 1000; // 1000 ml cada tres meses en verano
            unidadMes = dosisCadaTresMeses >= 1 ? 'lt' : 'ml';
    
            setResult({
                producto: 'Natabio',
                dosisPorMes: formatLitros(dosisCadaTresMeses),
                unidadMes,
            });
        } else if (temporada === 'invierno') {
            dosisPorMes = (litros / baseLitros) * (1000 / 3); // Aproximadamente 333.33 ml por mes en invierno
            dosisCadaTresMeses = dosisPorMes * 3; // Total acumulado cada tres meses en invierno
            unidadMes = dosisPorMes >= 1 ? 'lt' : 'ml';
    
            setResult({
                producto: 'Natabio',
                dosisCadaTresMeses: formatLitros(dosisCadaTresMeses),
                unidadMes,
            });
        }
    };
    

    const calcularPastillas200gr = () => {
        const baseLitros = 40000;
        let dosisPorSemana, dosisPorMes, unidadSemana, unidadMes;
    
        if (temporada === 'verano') {
            dosisPorSemana = (litros / baseLitros) * 2;
            dosisPorMes = dosisPorSemana * 4;
            unidadSemana = 'pastillas';
            unidadMes = 'pastillas';
    
            setResult({
                producto: 'Pastillas multiaccion 200gr',
                dosisPorSemana: parseFloat(dosisPorSemana.toFixed(2)),
                dosisPorMes: parseFloat(dosisPorMes.toFixed(2)),
                unidadSemana,
                unidadMes,
                frecuencia: 'semanal',
            });
        } else if (temporada === 'invierno') {
            dosisPorSemana = (litros / baseLitros);
            dosisPorMes = dosisPorSemana * 2;
            unidadSemana = 'pastillas';
            unidadMes = 'pastillas';
            setResult({
                producto: 'Pastillas multiacción 200gr',
                dosisPorSemana: parseFloat(dosisPorSemana.toFixed(2)),
                dosisPorMes: parseFloat(dosisPorMes.toFixed(2)),
                unidadSemana,
                unidadMes,
                frecuencia: 'cada dos semanas',
            });
        }
    };
    
    const calcularPastillas50gr = () => {
        const baseLitros = 40000;
        let dosisPorSemana, dosisPorMes, unidadSemana, unidadMes;
    
        if (temporada === 'verano') {
            dosisPorSemana = (litros / baseLitros) * 8;
            dosisPorMes = dosisPorSemana * 4;
            unidadSemana = 'pastillas';
            unidadMes = 'pastillas';
    
            setResult({
                producto: 'Pastillas multiaccion 50gr',
                dosisPorSemana: parseFloat(dosisPorSemana.toFixed(2)),
                dosisPorMes: parseFloat(dosisPorMes.toFixed(2)),
                unidadSemana,
                unidadMes,
                frecuencia: 'semanal',
            });
        } else if (temporada === 'invierno') {
            dosisPorSemana = (litros / baseLitros) * 2;
            dosisPorMes = dosisPorSemana * 4;
            unidadSemana = 'pastillas';
            unidadMes = 'pastillas';
            setResult({
                producto: 'Pastillas multiaccion 50gr',
                dosisPorSemana: parseFloat(dosisPorSemana.toFixed(2)),
                dosisPorMes: parseFloat(dosisPorMes.toFixed(2)),
                unidadSemana,
                unidadMes,
                frecuencia: 'cada dos semanas',
            });
        }
    };
    

    const formatLitros = (cantidad) => {
        if (cantidad >= 1000) {
            return (cantidad / 1000).toFixed(1);
        } else {
            return cantidad;
        }
    };

    return (
        <>
            <br /><br /><br /><br />
            <div className="container">
                <h1 className="my-4 text-center">Calcula cuánto producto necesitas para tu pileta</h1>
                <div className="d-flex justify-content-center">
                    <form onSubmit={handleSubmit} className="w-100">
                        <div className="row mb-3">
                            <div className="col-12">
                                <label htmlFor="producto" className="form-label">
                                    Seleccione un producto:
                                </label>
                                <select
                                    id="producto"
                                    className="form-control"
                                    value={producto}
                                    onChange={(e) => {
                                        setProducto(e.target.value);
                                        if (e.target.value === 'Alguicida' || e.target.value === 'Clarificador') {
                                            setTemporada('verano'); // Resetea a verano al cambiar Alguicida o Clarificador
                                        }
                                    }}
                                    required
                                >
                                    <option value="">Seleccione un producto</option>
                                    <option value="Alguicida">Alguicida</option>
                                    <option value="Clarificador">Clarificador</option>
                                    <option value="Cloro instantaneo">Cloro instantáneo</option>
                                    <option value="Cloro granulado">Cloro granulado</option>
                                    <option value="Natabio">Natabio</option>
                                    <option value="Pastillas multiaccion 200gr">Pastillas multiacción 200gr</option>
                                    <option value="Pastillas multiaccion 50gr">Pastillas multiacción 50gr</option>
                                </select>
                            </div>
                        </div>
                        <div className="row mb-3">
                            <div className="col-12">
                                <label htmlFor="litros" className="form-label">
                                    Litros de la pileta:
                                </label>
                                <input
                                    type="number"
                                    id="litros"
                                    className="form-control"
                                    value={litros}
                                    onChange={(e) => setLitros(e.target.value)}
                                    min="0"
                                    required
                                />
                            </div>
                        </div>
                        {(producto !== 'Alguicida' && producto !== 'Clarificador') && (
                            <div className="row mb-3">
                                <div className="col-12">
                                    <label htmlFor="temporada" className="form-label">
                                        Temporada:
                                    </label>
                                    <select
                                        id="temporada"
                                        className="form-control"
                                        value={temporada}
                                        onChange={(e) => setTemporada(e.target.value)}
                                        required
                                    >
                                        <option value="verano">Verano</option>
                                        <option value="invierno">Invierno</option>
                                    </select>
                                </div>
                            </div>
                        )}
                        <button type="submit" className="btn btn-primary w-100">
                            Calcular
                        </button>
                    </form>
                </div>

                {result && (
                    <div className="d-flex justify-content-center mt-4">
                    <div className="alert alert-success w-100">
                        <p>
                            <strong>Producto:</strong> {result.producto}
                        </p>
                        {result.dosisPorSemana && ( // Mostrar dosis por semana solo si hay valor
                            <p>
                                <strong>
                                    {result.producto.includes('Pastillas') ? 'Dosis ' : 'Dosis '}
                                    {result.frecuencia}
                                    {result.producto.includes('Pastillas') ? "" : temporada === 'verano' ? ' por día' : ' por semana'}:
                                </strong> {result.dosisPorSemana} {result.unidadSemana}
                            </p>
                        )}
                        {result.dosisPorMes && ( // Mostrar dosis por mes solo si hay valor
                            <p>
                                <strong>Dosis por mes:</strong> {result.dosisPorMes} {result.unidadMes}
                            </p>
                        )}
                        {producto === 'Natabio' && temporada === 'invierno' && (
                            <p>
                                <strong>Dosis cada 3 meses:</strong> {result.dosisCadaTresMeses} {result.unidadMes}
                            </p>
                        )}
                    </div>
                </div>)}
            </div>
        </>
    );
};

export default CalculadoraProductos;
