import React, { useState, useEffect, useRef } from 'react';
import MobilePage from './MobilePage'; // Importa la página para dispositivos móviles

function Iluminacion({ onBackgroundImageCheck}){
    const sectionRef = useRef(null);
    useEffect(() => {
        const sectionElement = sectionRef.current;
        if (sectionElement) {
            const style = window.getComputedStyle(sectionElement);
            const hasBackgroundImage = style.backgroundImage !== 'none';
            onBackgroundImageCheck(hasBackgroundImage);
        }
    }, [onBackgroundImageCheck]);


    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      // Actualiza el estado cuando cambie el ancho de la ventana
      const handleResize = () => {
        const newWidth = window.innerWidth;
       
        setWindowWidth(newWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
 return (
    <div>
      {windowWidth <= 480 ? (
        <MobilePage /> // Muestra la página para dispositivos móviles si es menor o igual a 480px
      ) : (
        // Muestra la página regular aquí
        <div>
          <div id="carouseliluminacion" className="carousel slide w-100 carousel-fade" data-bs-ride="carousel">
                <div className='carousel-inner'>
                    <div className='carousel-item active'>
                        <div className="d-flex w-100 d-block" id="imgiluminacion" ref={sectionRef} >

                            <div className='row align-self-center w-100 text-start' id='alineardescripcion'> 

                                <div className='col-lg-8 col-md-10 col-sm-12 col-xs-12'>
                                    <h1 id='tituloiluminacion'>Iluminación</h1>
                                    <p className='lead' id='descripcioniluminacion'>
                                        Instalamos sistemas de iluminación que cambiarán la estética de su pileta. Con nuestra amplia gama de opciones podemos crear un ambiente mágico y cautivador que realzará la belleza de tu piscina durante las horas de la noche.
                                        Ya sea que desees crear una atmósfera relajante y tranquila o una fiesta vibrante y llena de energía, nuestras luces LED te permitirán personalizar la experiencia visual de tu piscina según tu estado de ánimo y ocasión.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className='d-flex w-100 d-block' id='imgiluminacion2' ref={sectionRef}>
                            <div className='row align-self-center w-100 text-start' id='alineardescripcion'>
                                <div className='col-lg-8 col-md-10 col-sm-12 col-xs-12'>
                                    <h1 id='tituloiluminacion'>Iluminación</h1>
                                    <p className='lead' id='descripcioniluminacion'>
                                        Instalamos sistemas de iluminación que cambiarán la estética de su pileta. Con nuestra amplia gama de opciones podemos crear un ambiente mágico y cautivador que realzará la belleza de tu piscina durante las horas de la noche.
                                        Ya sea que desees crear una atmósfera relajante y tranquila o una fiesta vibrante y llena de energía, nuestras luces LED te permitirán personalizar la experiencia visual de tu piscina según tu estado de ánimo y ocasión.

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/*BOTONES */}
                <button className="carousel-control-prev" type="button" data-bs-target="#carouseliluminacion" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouseliluminacion" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>
            </div>
          {/* Coloca el contenido de tu página regular aquí */}
        </div>
      )}
    </div>
  );
}


 


export default Iluminacion











