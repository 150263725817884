import React, { useEffect, useState } from 'react';
import {Route, Routes} from 'react-router-dom';
import './App.css';
import Carga from './Components/Carga/Carga';
import MyNavBar from './Components/Navbar/MyNavBar';
import Home from './Components/Home/Home';
import Cobertores from './Components/Navbar/Cobertores';
import Iluminacion from './Components/Navbar/Iluminacion';
import Robots from './Components/Navbar/Robots';
import ClimatizacionSolar from './Components/Navbar/ClimatizacionSolar';
import Riegos from './Components/Navbar/Riegos';
import Productos from './Components/Navbar/Productos';
import PiletasPequenas from './Components/Navbar/PiletasPequenas';
import PiletasMedianas from './Components/Navbar/PiletasMedianas';
import PiletasGrandes from './Components/Navbar/PiletasGrandes';
import TermotanqueSolar from './Components/Navbar/TermotanqueSolar';
import BombasCalor from './Components/Navbar/BombasCalor';
import CalculoProductos from './Components/Navbar/CalculadoraProductos';

function App() {

  const [hasBackgroundImage, setHasBackgroundImage] = useState(false);

  const handleBackgroundImageCheck = (hasImage) => {
    setHasBackgroundImage(hasImage);
  };

  return (
    <div>
      <Carga />
      <MyNavBar hasBackgroundImage={hasBackgroundImage}/> 
      <Routes>
        <Route path="/" element={<Home onBackgroundImageCheck={handleBackgroundImageCheck} />} />
        <Route path="/piletaspequeñas" element={<PiletasPequenas />} exact/>
        <Route path="/piletasmedianas" element={<PiletasMedianas />} exact/>
        <Route path="/piletasgrandes" element={<PiletasGrandes />} exact/>
        <Route path='/cobertores' element={<Cobertores onBackgroundImageCheck={handleBackgroundImageCheck}/>} exact/>
        <Route path='/iluminacion' element={ <Iluminacion onBackgroundImageCheck={handleBackgroundImageCheck}/>} exact/>
        <Route path='/robots' element={ <Robots/>} exact/>
        <Route path='/bombascalor' element={ <BombasCalor/>} exact/>
        <Route path='/solar' element={ <ClimatizacionSolar onBackgroundImageCheck={handleBackgroundImageCheck}/>} exact/>
        <Route path='/riegos' element={ <Riegos onBackgroundImageCheck={handleBackgroundImageCheck}/>} exact/>
        <Route path='/productos' element={ <Productos onBackgroundImageCheck={handleBackgroundImageCheck}/>} exact/>
        <Route path="/termotanquesolar" element={<TermotanqueSolar />} exact/>
        <Route path="/calculoproductos" element={<CalculoProductos />} exact/>
      </Routes>
      
    </div>
  );
}

export default App;
