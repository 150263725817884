import React, { useEffect, useRef } from 'react'
import iluminacion1 from './Img/481-767/Iluminacion/Iluminacion1-481-767.jpg'
import iluminacion2 from './Img/481-767/Iluminacion/iluminacion2.1-481-767.jpg'

function MobilePage() {


  return (
    <div className='overflow'>   <br/><br/><br/> <br/> 
        <div className="card mb-3">
  <div className="row ">
    <div className="col-md-3 ">
    <div id="carouselIluminacionMobile" className="carousel slide" data-bs-ride="carousel">
  <div className="carousel-inner">
    <div className="carousel-item active">
      <img src={iluminacion1} className="img-fluid" alt="..."/>
    </div>
    <div className="carousel-item">
      <img src={iluminacion2} className="d-block w-100" alt="..."/>
    </div>
  </div>
  <button className="carousel-control-prev" type="button" data-bs-target="#carouselIluminacionMobile" data-bs-slide="prev">
    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Previous</span>
  </button>
  <button className="carousel-control-next" type="button" data-bs-target="#carouselIluminacionMobile" data-bs-slide="next">
    <span className="carousel-control-next-icon" aria-hidden="true"></span>
    <span className="visually-hidden">Next</span>
  </button>
</div>
      
    </div>
    <div className="col-md-9">
      <div className="card-body ">
        <p className="card-text descripcionmobile ">
        Instalamos sistemas de iluminación que cambiarán la estética de su pileta. Con nuestra amplia gama de opciones podemos crear un ambiente mágico y cautivador que realzará la belleza de tu piscina durante las horas de la noche.
                                        Ya sea que desees crear una atmósfera relajante y tranquila o una fiesta vibrante y llena de energía, nuestras luces LED te permitirán personalizar la experiencia visual de tu piscina según tu estado de ánimo y ocasión.
        </p>
      </div>
    </div>
  </div>
</div>
    </div>
  )
}

export default MobilePage