import s50 from './Img/robots/S50.jpg';
import s100 from './Img/robots/S100.jpg';
import s200 from './Img/robots/S200.jpg';
import s300 from './Img/robots/S300.jpg';
import s400 from './Img/robots/S400.jpg';
import poolUp from './Img/robots/Pool-Up.jpg';
import e10 from './Img/robots/E10.jpg';
import e20 from './Img/robots/E20.jpg';
import m600 from './Img/robots/M600.jpg';
import m700 from './Img/robots/M700.jpg';
import wave from './Img/robots/Wave-30.jpg';
import prox2 from './Img/robots/Pro-x2-.jpg';
import pro2x2 from './Img/robots/Pro-2x2.jpg';

function Robots() {
    return (
        <>
            <br />
            <br /><br /><br /><br />

            <div className="row p-2 ms-5 me-5 border-0 principalrow">
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={s50} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin S50</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 8 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>12 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>No</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>No</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={s100} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin S100</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 10 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>15 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>No</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={s200} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin S200</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 12 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>18 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>



                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={s300} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin S300</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 12 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>18 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={s400} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin S400</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 15 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>18 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={poolUp} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin Pool Up</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 8 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>12 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>No</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={e10} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin E-10</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 8 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>12 metros</div>
                            </div> 
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>No</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>No</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={e20} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin E-20</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 10 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>15 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>No</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={m600} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin M-600</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 15 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>18 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={m700} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin M-700</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 15 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>18 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={wave} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin Wave-30</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 15 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>18 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={prox2} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin Pro x2 Combinado</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 25 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>30 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={pro2x2} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">Dolphin 2x2 Combinado</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Metros de la piscina:</div>
                                <div className='col-6'>Hasta 50 metros</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Longitud del cable:</div>
                                <div className='col-6'>40 metros</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Limpieza de paredes:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Cepilla línea de flotación:</div>
                                <div className='col-6'>Sí</div>
                            </div>
                            <div className='row  bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Smartphone app control:</div>
                                <div className='col-6'>No</div>
                            </div>
                        </div>
                    </div>
                </div>




            </div>



        </>
    )
}

export default Robots