import React from 'react'
import MS130 from './Img/bombas de calor/MS130 Inverpad 230 V.jpg';


function BombasSilence() {
  return (
    <><br />
    <div className="row p-2 ms-5 me-5 border-0 principalrow">
        <h5 className="card-title text-center robot pb-3 ">Bombas de calor NATACLOR - Mr. SILENCE INVERPAD </h5>
        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="card">
                <img src={MS130} className="card-img-top ps-5 pe-5" alt="..." />
                <div className="card-body body-robots">
                    <h5 className="card-title text-center robot">MS130 - 230V</h5>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Potencia:</div>
                        <div className='col-6'>13 kW</div>
                    </div>
                    <div className='row  p-1'>
                        <div className='col-6'>Volúmen máximo:</div>
                        <div className='col-6'>65 m3</div>
                    </div>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Conexión de agua:</div>
                        <div className='col-6'>50mm</div>
                    </div>
                    <div className='row p-1'>
                        <div className='col-6'>Compatible con Wi-fi:</div>
                        <div className='col-6'>SI</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="card">
                <img src={MS130} className="card-img-top ps-5 pe-5" alt="..." />
                <div className="card-body body-robots">
                    <h5 className="card-title text-center robot">MS170 - 230V</h5>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Potencia:</div>
                        <div className='col-6'>17,5 kW</div>
                    </div>
                    <div className='row  p-1'>
                        <div className='col-6'>Volumen máximo:</div>
                        <div className='col-6'>80 m3</div>
                    </div>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Conexión de agua:</div>
                        <div className='col-6'>50mm</div>
                    </div>
                    <div className='row p-1'>
                        <div className='col-6'>Compatible con Wi-fi:</div>
                        <div className='col-6'>SI</div>
                    </div>
                </div>
            </div>
        </div>


        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="card">
                <img src={MS130} className="card-img-top ps-5 pe-5" alt="..." />
                <div className="card-body body-robots">
                    <h5 className="card-title text-center robot">MS210  - 230V</h5>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Potencia:</div>
                        <div className='col-6'>21 kW</div>
                    </div>
                    <div className='row  p-1'>
                        <div className='col-6'>Volumen máximo:</div>
                        <div className='col-6'>95 m3</div>
                    </div>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Conexión de agua:</div>
                        <div className='col-6'>50mm</div>
                    </div>
                    <div className='row p-1'>
                        <div className='col-6'>Compatible con Wi-fi:</div>
                        <div className='col-6'>SI</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="card">
                <img src={MS130} className="card-img-top ps-5 pe-5" alt="..." />
                <div className="card-body body-robots">
                    <h5 className="card-title text-center robot">MS280 -230V</h5>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Potencia:</div>
                        <div className='col-6'>28 kW</div>
                    </div>
                    <div className='row  p-1'>
                        <div className='col-6'>Volumen máximo:</div>
                        <div className='col-6'>120 m3</div>
                    </div>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Conexión de agua:</div>
                        <div className='col-6'>50mm</div>
                    </div>
                    <div className='row p-1'>
                        <div className='col-6'>Compatible con Wi-fi:</div>
                        <div className='col-6'>SI</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="card">
                <img src={MS130} className="card-img-top ps-5 pe-5" alt="..." />
                <div className="card-body body-robots">
                    <h5 className="card-title text-center robot">MS280S -320V</h5>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Potencia:</div>
                        <div className='col-6'>28 kW</div>
                    </div>
                    <div className='row  p-1'>
                        <div className='col-6'>Volumen máximo:</div>
                        <div className='col-6'>120 m3</div>
                    </div>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Conexión de agua:</div>
                        <div className='col-6'>50mm</div>
                    </div>
                    <div className='row p-1'>
                        <div className='col-6'>Compatible con Wi-fi:</div>
                        <div className='col-6'>SI</div>
                    </div>
                </div>
            </div>
        </div>

        <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
            <div className="card">
                <img src={MS130} className="card-img-top ps-5 pe-5" alt="..." />
                <div className="card-body body-robots">
                    <h5 className="card-title text-center robot">MS350S -320V</h5>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Potencia:</div>
                        <div className='col-6'>35,2 kW</div>
                    </div>
                    <div className='row  p-1'>
                        <div className='col-6'>Volumen máximo:</div>
                        <div className='col-6'>160 m3</div>
                    </div>
                    <div className='row bg-secondary bg-opacity-25 p-1'>
                        <div className='col-6'>Conexión de agua:</div>
                        <div className='col-6'>50mm</div>
                    </div>
                    <div className='row p-1'>
                        <div className='col-6'>Compatible con Wi-fi:</div>
                        <div className='col-6'>SI</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</>
  )
}

export default BombasSilence