import React, { useState, useEffect } from 'react'
import termotanque from './Img/termotanque1.jpg';
import termotanque3 from './Img/termotanque3.jpg';



function TermotanqueSolar() {
    return (
        <>
            <br /><br /> <br /><br />
            <div className="d-flex w-100" id="termotanquesolar" >
                <div className="row align-self-center  px-5 text-white">

                    <h1 className='mt-5'>Agua caliente</h1>
                    <h4 className='text-white text-opacity-75'>en tu casa con los</h4>
                    <h2 className=''>TERMOTANQUES SOLARES</h2>
                </div>
            </div>
<br/>
            <div className='row w-100'>
                <div className='col-6' id='termotanqueimg'><img className='img-fluid  h-100' src={termotanque}/>
                
                </div>
                <div className='col-6' id='termotanque'>
                    <div className='container termotanque'>
                        <div className='card-title'><h2  className='termotanquetitulo'>TERMOTANQUES NO PRESURIZABLES</h2></div>
                        <div className='row p-2'>
                            <div className='col-4 bg-secondary bg-opacity-50'><b>Modelo</b></div>
                            <div className='col-7 bg-secondary bg-opacity-50' ><b>Capacidad</b></div>
                            <div className='col-4'>SGJ100</div>
                            <div className='col-4'> para 2 a 3 personas</div>
                            <div className='col-3'>100L</div>
                            <div className='col-4 bg-secondary bg-opacity-50'>SGJ150</div>
                            <div className='col-4 bg-secondary bg-opacity-50' > para 3 a 4 personas</div>
                            <div className='col-3 bg-secondary bg-opacity-50'>150L</div>
                            <div className='col-4'>SGJ200</div>
                            <div className='col-4'> para 4 a 5 personas</div>
                            <div className='col-3'>200L</div>
                            <div className='col-4 bg-secondary bg-opacity-50'>SGJ300</div>
                            <div className='col-4 bg-secondary bg-opacity-50'> para 6 a 7 personas</div>
                            <div className='col-3 bg-secondary bg-opacity-50'>300L</div>
                        </div>
                    </div>
                </div>
            </div><br/>
            <div className='row p-2 w-100'>
                
                <div className='col-6'id='termotanque'>
                    <div className='container termotanque'>
                        <div className='card-title'><h2 className='termotanquetitulo'>TERMOTANQUES PRESURIZABLES</h2></div>
                        <div className='row'>
                            <div className='col-4 bg-secondary bg-opacity-50'><b>Modelo</b></div>
                            <div className='col-7 bg-secondary bg-opacity-50' ><b>Capacidad</b></div>
                            <div className='col-4'>HPJ200</div>
                            <div className='col-4'> para 4 a 5 personas</div>
                            <div className='col-3'>200L</div>
                            <div className='col-4 bg-secondary bg-opacity-50'>HPJ300</div>
                            <div className='col-4 bg-secondary bg-opacity-50' > para 6 a 7 personas</div>
                            <div className='col-3 bg-secondary bg-opacity-50'>300L</div>
                        </div>
                    </div>
                </div>
                <div className='col-6'id='termotanqueimg'><img className='img-fluid termotanqueimg h-100' src={termotanque3} /></div>
            </div>

        </>
    )
}

export default TermotanqueSolar