import React from 'react'
import cobertor1 from './Img/481-767/Cobertor/cobertor-481-767.jpg';
import cobertor2 from './Img/481-767/Cobertor/cobertorbeige-481-767.jpg';
import cerramiento1 from './Img/481-767/Cercos/cerconegro-481-767.jpg';
import cerramiento2 from './Img/481-767/Cercos/cercogris-481-767.jpg';

function MobilePageCobertor() {
  return (
    <div className='overflow'>   <br/><br/><br/> <br/> 
    <div className="card mb-3">
<div className="row ">
<div className="col-md-3 ">
<div id="carouselCobertoresMobile" className="carousel slide" data-bs-ride="carousel">
<div className="carousel-inner">
<div className="carousel-item active">
  <img src={cobertor1} className="img-fluid" alt="..."/>
</div>
<div className="carousel-item">
  <img src={cobertor2} className="d-block w-100" alt="..."/>
</div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselCobertoresMobile" data-bs-slide="prev">
<span className="carousel-control-prev-icon" aria-hidden="true"></span>
<span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselCobertoresMobile" data-bs-slide="next">
<span className="carousel-control-next-icon" aria-hidden="true"></span>
<span className="visually-hidden">Next</span>
</button>
</div>
  
</div>
<div className="col-md-9">
  <div className="card-body ">
  <h5 className="card-title">Cobertores</h5>
    <p className="card-text descripcionmobile ">
    Los cobertores para piscinas son una solución práctica para proteger y mantener tu piscina en óptimas condiciones durante todo el año.
                                                Además de su función protectora, también brindan seguridad.<br></br> Al cubrir completamente la piscina, ayudan a prevenir accidentes,
                                                especialmente para hogares con niños pequeños o mascotas.
    </p>
  </div>
</div>
</div>
</div>

<div className="card mb-3">
<div className="row ">
<div className="col-md-3 ">
<div id="carouselCercosMobile" className="carousel slide" data-bs-ride="carousel">
<div className="carousel-inner">
<div className="carousel-item active">
  <img src={cerramiento1} className="img-fluid" alt="..."/>
</div>
<div className="carousel-item">
  <img src={cerramiento2} className="d-block w-100" alt="..."/>
</div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselICercosMobile" data-bs-slide="prev">
<span className="carousel-control-prev-icon" aria-hidden="true"></span>
<span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselCercosMobile" data-bs-slide="next">
<span className="carousel-control-next-icon" aria-hidden="true"></span>
<span className="visually-hidden">Next</span>
</button>
</div>
  
</div>
<div className="col-md-9">
  <div className="card-body ">
  <h5 className="card-title">Cerramientos</h5>
    <p className="card-text descripcionmobile fs-5">
    Descubre nuestra solución de cerramientos para piscinas.
                                                Con nuestro sistema de seguridad, podrás proteger tu piscina de manera efectiva y brindar tranquilidad a tu familia.
                                                Nuestros cercos son duraderos, de fácil instalación y cumplen con los estándares de seguridad más exigentes. <br />
                                                ¡Disfruta de tu piscina con total paz mental gracias a nuestros cercos perimetrales!
    </p>
  </div>
</div>
</div>
</div>




</div>
)
}


export default MobilePageCobertor