import React from 'react'
import BombasEco from './BombasEco'
import BombasSilence from './BombasSilence'
import BombasTop from './BombasTop'

function BombasCalor() {
    return (<><br /><br /><br /><br /><br />
        <div><BombasTop /></div>
        <div> <BombasEco /></div>
        <div> <BombasSilence /></div>
    </>)
}

export default BombasCalor