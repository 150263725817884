import React from 'react'
import EPN07 from './Img/bombas de calor/EPN07 Inverter 230 V.jpg';


function BombasEco() {
    return (
        <><br />
            <div className="row p-2 ms-5 me-5 border-0 principalrow">
                <h5 className="card-title text-center robot pb-3 ">Bombas de calor NATACLOR - Mr. ECO</h5>
                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={EPN07} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">EPN07 - 230V</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Potencia:</div>
                                <div className='col-6'>7 kW</div>
                            </div>
                            <div className='row  p-1'>
                                <div className='col-6'>Volúmen máximo:</div>
                                <div className='col-6'>30 m3</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Conexión de agua:</div>
                                <div className='col-6'>50mm</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Compatible con Wi-fi:</div>
                                <div className='col-6'>SI</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={EPN07} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">EPN09 - 230V</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Potencia:</div>
                                <div className='col-6'>9 kW</div>
                            </div>
                            <div className='row  p-1'>
                                <div className='col-6'>Volumen máximo:</div>
                                <div className='col-6'>45 m3</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Conexión de agua:</div>
                                <div className='col-6'>50mm</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Compatible con Wi-fi:</div>
                                <div className='col-6'>SI</div>
                            </div>
                        </div>
                    </div>
                </div>


                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={EPN07} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">EPN13 - 230V</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Potencia:</div>
                                <div className='col-6'>13 kW</div>
                            </div>
                            <div className='row  p-1'>
                                <div className='col-6'>Volumen máximo:</div>
                                <div className='col-6'>65 m3</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Conexión de agua:</div>
                                <div className='col-6'>50mm</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Compatible con Wi-fi:</div>
                                <div className='col-6'>SI</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={EPN07} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">EPN16 -230V</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Potencia:</div>
                                <div className='col-6'>16 kW</div>
                            </div>
                            <div className='row  p-1'>
                                <div className='col-6'>Volumen máximo:</div>
                                <div className='col-6'>75 m3</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Conexión de agua:</div>
                                <div className='col-6'>50mm</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Compatible con Wi-fi:</div>
                                <div className='col-6'>SI</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={EPN07} className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">EPN20 -230V</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Potencia:</div>
                                <div className='col-6'>20,2 kW</div>
                            </div>
                            <div className='row  p-1'>
                                <div className='col-6'>Volumen máximo:</div>
                                <div className='col-6'>90 m3</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Conexión de agua:</div>
                                <div className='col-6'>50mm</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Compatible con Wi-fi:</div>
                                <div className='col-6'>SI</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-md-6 col-sm-6 col-xs-12 mb-4">
                    <div className="card">
                        <img src={EPN07}className="card-img-top ps-5 pe-5" alt="..." />
                        <div className="card-body body-robots">
                            <h5 className="card-title text-center robot">EPN24 -230V</h5>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Potencia:</div>
                                <div className='col-6'>24,2 kW</div>
                            </div>
                            <div className='row  p-1'>
                                <div className='col-6'>Volumen máximo:</div>
                                <div className='col-6'>110 m3</div>
                            </div>
                            <div className='row bg-secondary bg-opacity-25 p-1'>
                                <div className='col-6'>Conexión de agua:</div>
                                <div className='col-6'>50mm</div>
                            </div>
                            <div className='row p-1'>
                                <div className='col-6'>Compatible con Wi-fi:</div>
                                <div className='col-6'>SI</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BombasEco