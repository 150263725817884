import React, { useEffect, useRef, useState } from 'react'
import MobilePageRiegos from './MobilePageRiegos';

function Riegos({ onBackgroundImageCheck }) {
    const sectionRef = useRef(null);
    useEffect(() => {
        const sectionElement = sectionRef.current;
        if (sectionElement) {
            const style = window.getComputedStyle(sectionElement);
            const hasBackgroundImage = style.backgroundImage !== 'none';
            onBackgroundImageCheck(hasBackgroundImage);
        }
    }, [onBackgroundImageCheck]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  
    useEffect(() => {
      // Actualiza el estado cuando cambie el ancho de la ventana
      const handleResize = () => {
        const newWidth = window.innerWidth;
       
        setWindowWidth(newWidth);
      };
  
      window.addEventListener('resize', handleResize);
  
      return () => {
        window.removeEventListener('resize', handleResize);
      };
    }, []);
    return (
        <div>
          {windowWidth <= 480 ? (
            <MobilePageRiegos /> // Muestra la página para dispositivos móviles si es menor o igual a 480px
          ) : (
            // Muestra la página regular aquí
            <div>
               <div id='carouselriegos' className='carousel slide w-100 carousel-fade' data-bs-ride="carousel">
                <div className='carousel-inner'>
                    <div className='carousel-item active w-100'>
                        <div className='d-flex w-100' id='imgriego' ref={sectionRef}>
                            <div className='row align-self-center w-100 text-start' id='alineardescripcion'>

                                <div className='col-md-6'>
                                    <h1 id='tituloriego'>Riegos</h1>
                                    <p className='lead' id='descripcionriego'>
                                    Instalamos sistemas de riegos logrando un máximo de aprovechabilidad del área para una mayor economía de agua y energía.
                                    </p>
                                </div>
                                <div className='col-md-6'></div>
                            </div>
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className='d-flex w-100 img-responsive' id='imgriego2' ref={sectionRef}>
                            <div className='row align-self-center w-100 text-start' id='alineardescripcion'>

                                <div className='col-md-6'>
                                    <h1 id='tituloriego'>Riegos</h1>
                                    <p className='lead' id='descripcionriego'>
                                    Instalamos sistemas de riegos logrando un máximo de aprovechabilidad del área para una mayor economía de agua y energía.
                                    </p>
                                </div>
                                <div className='col-md-6'></div>
                            </div>
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className='d-flex w-100' id='imgriego3' ref={sectionRef}>
                            <div className='row align-self-center w-100 text-start'id='alineardescripcion'>

                                <div className='col-md-6'>
                                    <h1 id='tituloriego'>Riegos</h1>
                                    <p className='lead' id='descripcionriego'>
                                    Instalamos sistemas de riegos logrando un máximo de aprovechabilidad del área para una mayor economía de agua y energía.
                                    </p>
                                </div>
                                <div className='col-md-6'></div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*BOTONES */}
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselriegos" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselriegos" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>

            </div>
              {/* Coloca el contenido de tu página regular aquí */}
            </div>
          )}
        </div>
      );
}

export default Riegos