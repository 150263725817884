import React, { useEffect, useState } from 'react'
import logo from '../../Logo/logofinal.png'
import logoblanco from '../../Logo/logofinalblanco.png'



function MyNavBar({ hasBackgroundImage }) {
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    if (hasBackgroundImage && !scrolled) {
      // Agregar clase
      document.querySelector('.navbar').classList.add('solid');
      document.querySelector('.navbar-toggler-icon').classList.add('solid2');
      document.querySelector('.navbar-toggler').classList.add('solid3');
      const navLinks = document.querySelectorAll('.nav-link');
      navLinks.forEach(link => link.classList.add('color'));
      document.getElementById('nav-brand').src = logoblanco;

    } else {
      // Eliminar clase
      document.querySelector('.navbar').classList.remove('solid');
      const navLinks = document.querySelectorAll('.nav-link');
      navLinks.forEach(link => link.classList.remove('color'));
      document.getElementById('nav-brand').src = logo;
      document.querySelector('.navbar-toggler-icon').classList.remove('solid2');
      document.querySelector('.navbar-toggler').classList.remove('solid3')
    }
  }, [hasBackgroundImage, scrolled]);

  useEffect(() => {
    const handleScroll = () => {

      if (window.scrollY > 500) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    }
  }, []);


  return (
    <>


      <nav className="navbar fixed-top navbar-expand-lg navbar-light" id="navbar2">
        <div className="container">

          <a><button className="navbar-toggler white" type="button" data-bs-toggle="collapse" data-bs-target="#navbar"
            aria-controls="navbar" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon custom-toggler"></span>
          </button></a>

          <a className="navbar-brand mt-2 mt-lg-0" href="../">
              <img src={logo} id="nav-brand" className='w-100' /> 
          </a>

          <div className="navbar-collapse collapse justify-content-end" id="navbar">


            <ul className="navbar-nav">
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink" role="button"
                  data-bs-toggle="dropdown" data-bs-auto-close="outside"aria-expanded="false">
                  Piletas
                </a>
                <ul className="dropdown-menu dropdown-menu-dark"  aria-labelledby="navbarDarkDropdownMenuLink">
                   
                  <li className='nav-item dropdown dropend'>
                    <a className="dropdown-item dropdown-toggle" id="multilevelDropdownMenu1" href="#"
                    aria-expanded="false" role='button' data-bs-toggle="dropdown">Modelos</a>
                    <ul className="dropdown-menu dropdown-menu-dark">
                      <li>
                        <a className="dropdown-item" href="piletaspequeñas">Piletas pequeñas</a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="piletasmedianas">Piletas medianas</a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="piletasgrandes">Piletas grandes</a>
                      </li>
                      </ul>
                  </li>
                  <li><a className="dropdown-item" href="cobertores">Cobertores y cerramientos</a></li>
                  <li><a className="dropdown-item" href="iluminacion">Iluminación</a></li>
                  <li><a className="dropdown-item" href="robots">Robots de limpieza</a></li>
                </ul>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink2" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  Climatización</a>
                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink2">
                  <li><a className="dropdown-item" href="bombascalor">Bombas de calor</a></li>
                  <li><a className="dropdown-item" href="#">Climatización a gas</a></li>
                  <li><a className="dropdown-item" href="solar">Climatización solar</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="riegos">Riegos</a>
              </li>
              <li className="nav-item dropdown">
                <a className="nav-link dropdown-toggle" href="#" id="navbarDarkDropdownMenuLink3" role="button"
                  data-bs-toggle="dropdown" aria-expanded="false">
                  Energía solar</a>
                <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDarkDropdownMenuLink3">
                  <li><a className="dropdown-item" href="solar">Climatización de piletas</a></li>
                  <li><a className="dropdown-item" href="termotanquesolar">Termotanque solar</a></li>
                  <li><a className="dropdown-item" href="#">Energía electrica fotovoltaica</a></li>
                </ul>
              </li>
              <li className="nav-item">
                <a className="nav-link" href="productos">Productos</a>
              </li>
            </ul>
          </div>
        </div>
      </nav>

    </>
  )
}

export default MyNavBar
