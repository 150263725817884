import React from 'react'
import climatizacion1 from './Img/solar1.jpg';
import climatizacion2 from './Img/solar2.jpg';

function MobilePageClimatizacion() {
  return (
    <div className='overflow'>
  <br/><br/><br/> <br/> 
    <div className="card mb-3">
<div className="row">
<div className="col-md-3">
<div id="carouselIluminacionMobile" className="carousel slide w-100" data-bs-ride="carousel">
<div className="carousel-inner">
<div className="carousel-item active">
  <img src={climatizacion1} className="img-fluid w-100" alt="..."/>
</div>
<div className="carousel-item">
  <img src={climatizacion2} className="d-block w-100" alt="..."/>
</div>
</div>
<button className="carousel-control-prev" type="button" data-bs-target="#carouselIluminacionMobile" data-bs-slide="prev">
<span className="carousel-control-prev-icon" aria-hidden="true"></span>
<span className="visually-hidden">Previous</span>
</button>
<button className="carousel-control-next" type="button" data-bs-target="#carouselIluminacionMobile" data-bs-slide="next">
<span className="carousel-control-next-icon" aria-hidden="true"></span>
<span className="visually-hidden">Next</span>
</button>
</div>
  
</div>
<div className="col-md-9">
  <div className="card-body ">
    <p className="card-text descripcionmobile ">
    Nuestra climatización solar es una elección sostenible y eficaz para mantener la temperatura perfecta en tu piscina. Funciona a través de paneles solares estratégicamente ubicados que capturan la energía solar y la transfieren al agua de la piscina. 
    Este sistema no solo reduce tus gastos de calefacción de manera significativa, sino que también abraza la sostenibilidad al utilizar una fuente de energía limpia y renovable, contribuyendo así a la protección del medio ambiente.
    </p>
  </div>
</div>
</div>
</div>
</div>
  )
}

export default MobilePageClimatizacion