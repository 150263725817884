import React from 'react'
import wap from '../Carga/wap2.png'

function Carga() {
  return (
    <>
    <style>
  @import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap');
</style>
 <div className="wap"><a href='https://wa.me/542926410622?text=Me%20gustaría%20más%20información%20sobre...'><img src={wap} width="50%" className="m-3"/></a></div>
   </>
  )
}

export default Carga