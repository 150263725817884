import React from 'react'
import alejandrita from './Img/grandes/Alejandrita.png';
import alejandritaIlustracion from './Img/grandes/Alejandrita ilustracion.png';
import aventurina from './Img/grandes/Aventurina.png';
import aventurinaIlustracion from './Img/grandes/Aventurina ilustracion.png';
import espinela from './Img/grandes/Espinela.png';
import espinelaIlustracion from './Img/grandes/Espinela ilustracion.png';
import jade from './Img/grandes/Jade.png';
import jadeIlustracion from './Img/grandes/Jade ilustracion.png';
import onix from './Img/grandes/Onix.png';
import onixIlustracion from './Img/grandes/Onix ilustracion.png';
import zafiro from './Img/grandes/Zafiro.png';
import zafiroIlustracion from './Img/grandes/Zafiro ilustracion.png';
import zafiroazul from './Img/grandes/Zafiro Azul.png';
import zafiroazulIlustracion from './Img/grandes/Zafiro Azul ilustracion.png';
import diamanterojoilustracion from './Img/grandes/Diamante_Rojo_ilustracion-transformed.png';


function PiletasGrandes() {
  return (
    <>
    <br></br><br></br><br></br>

    <div className='mt-5 w-100'>
      <div className='row m-1'>
        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Zafiro Azul</h4>
            <img src={zafiroazul} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 8,0 m</div>
                <div className='card border-0 ps-2'>Ancho: 3,60 m</div>
                <div className='card ps-2'>Profundidad: 1,40 a 1,50 m</div>
                <div className='card border-0 ps-2'>Volumen: 41.000 lt</div>
              </div>
            </div>
            <br />
            <img src={zafiroazulIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>

        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Onix</h4>
            <img src={onix} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 8,0 m</div>
                <div className='card border-0 ps-2'>Ancho: 4,00 m</div>
                <div className='card ps-2'>Profundidad: 1,45 a 1,60 m</div>
                <div className='card border-0 ps-2'>Volumen: 48.000 lt</div>
              </div>
            </div>
            <br />
            <img src={onixIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>

        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Aventurina</h4>
            <img src={aventurina} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 9,0 m</div>
                <div className='card border-0 ps-2'>Ancho: 4,00 m</div>
                <div className='card ps-2'>Profundidad: 1,40 a 1,60 m</div>
                <div className='card border-0 ps-2'>Volumen: 54.000 lt</div>
              </div>
            </div>
            <br />
            <img src={aventurinaIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>


      </div>
    </div>
    




    <div className='mt-5 w-100'>
      <div className='row m-1'>
        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Espinela</h4>
            <img src={espinela} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 9,0 m</div>
                <div className='card border-0 ps-2'>Ancho: 3,75 m</div>
                <div className='card ps-2'>Profundidad: 1,40 a 1,50 m</div>
                <div className='card border-0 ps-2'>Volumen: 49.000 lt</div>
              </div>
            </div>
            <br />
            <img src={espinelaIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>

        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Alejandrita</h4>
            <img src={alejandrita} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 10,0 m</div>
                <div className='card border-0 ps-2'>Ancho: 4,0 m</div>
                <div className='card ps-2'>Profundidad: 1,40 a 1,60 m</div>
                <div className='card border-0 ps-2'>Volumen: 60.000 lt</div>
              </div>
            </div>
            <br />
            <img src={alejandritaIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>

        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Jade</h4>
            <img src={jade} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 8,0 m</div>
                <div className='card border-0 ps-2'>Ancho: 3,30 m</div>
                <div className='card ps-2'>Profundidad: 1,40 a 1,45 m</div>
                <div className='card border-0 ps-2'>Volumen: 32.000 lt</div>
              </div>
            </div>
            <br />
            <img src={jadeIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>


      </div>
    </div>


    <div className='mt-5 w-100'>
      <div className='row m-1'>
        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Zafiro</h4>
            <img src={zafiro} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 8,0 m</div>
                <div className='card border-0 ps-2'>Ancho: 3,60 m</div>
                <div className='card ps-2'>Profundidad: 1,40 a 1,50 m</div>
                <div className='card border-0 ps-2'>Volumen: 41.000 lt</div>
              </div>
            </div>
            <br />
            <img src={zafiroIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>
        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Diamante Rojo</h4>
            {/*  <img src={zafiro} className='card-img-top px-3'alt='Circón'/>  */}
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 10,0 m</div>
                <div className='card border-0 ps-2'>Ancho: 4,00 m</div>
                <div className='card ps-2'>Profundidad: 1,40 a 1,60 m</div>
                <div className='card border-0 ps-2'>Volumen: 60.000 lt</div>
              </div>
            </div>
            <br />
            <img src={diamanterojoilustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>



      </div>
    </div>


    
    </>
  )
}

export default PiletasGrandes