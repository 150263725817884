import React, { useEffect, useRef } from 'react'
import experiencia from './img/experiencia.png'
import emailjs from '@emailjs/browser';




function Home({ onBackgroundImageCheck }) {
    
    const sectionRef = useRef(null);
    useEffect(() => {
        const sectionElement = sectionRef.current;
        const style = window.getComputedStyle(sectionElement);
        const hasBackgroundImage = style.backgroundImage !== 'none';
    
        onBackgroundImageCheck(hasBackgroundImage);
      }, [onBackgroundImageCheck]);

      const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_qv7nuo5', 'template_zfbzyvx', form.current, 'PsgF486LmFt3VVpZ9')
      .then((result) => {
        window.alert("El mensaje se envió con éxito");
      }, (error) => {
          window.alert("No pudimos enviar el mensaje en este momento.");
      });
  };
   
    return (
        <>
        
            <section id='my-section'>
                <div className="experiencia" ><img src={experiencia} width="85%" alt='30 años de experiencia'/></div>
                
                <div className="d-flex" id="img" ref={sectionRef}>
                    <div className="row justify-content-end align-self-center w-100 text-end mt-5 px-5">
                        <div className="col-md-8 col-xs-12 ">
                            <p className="lead" id="descripcion2">Aguas Cristalinas es una empresa fundada en 1993, siendo uno de los pioneros en la zona en el servicio de mantenimiento y limpieza de piletas de natación.
                                La empresa fue evolucionando desde el comienzo y los requerimientos de los clientes hicieron que se diversificaran los tipos de servicios brindados. Así es que comenzamos a construir y reciclar piscinas de hormigón.
                                Con el tiempo incorporamos una segunda alternativa de material en piletas de fibra de vidrio de la marca Acquam, de la que somos representantes.<br/>
                                Bajo capacitación constante en seminarios y congresos, y con tecnología de avanzada, ofrecemos los mejores productos y servicios relacionados a piscinas, sistemas de riegos, cobertores, cerramientos y energía renovable.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <br></br><br></br>
            <section>
                <div className='row container mx-auto'>
                    <div className='col-12 col-sm-5 mx-auto p-2 text-dark'id='containerformizq'>
                        <h2><strong>Contacto</strong> </h2><br></br>
                        <h6>No dudes en comunicarte con nosotros si tienes alguna consulta.</h6><br></br>
                        <h6><strong>Encontranos en </strong></h6>
                        <h6>Cristobal Colon 1927, Coronel Suarez, Buenos Aires</h6><br></br>
                        <h6><strong>Teléfono</strong></h6>
                        <h6>+54 2926 413836</h6>
                    </div>

                    <div className='col-12 col-sm-7' id='containerformder'>
                        <div className='formbold-main-wrapper'>
                            <div className='formbold-dorm-wrapper w-100'>
                                <form ref={form} onSubmit={sendEmail}>
                                    <div className='formbold-input-flex'>
                                        <div>
                                            <label htmlFor='firstname' className='formbold-form-label'autoComplete="given-name"><strong>Nombre</strong></label>
                                            <input type='text' name='user_name' id='firstname' placeholder='Nombre' className='formbold-form-input' />
                                        </div>
                                        <div>
                                            <label htmlFor="lastname" className="formbold-form-label"autoComplete="family-name"> <strong>Apellido</strong> </label>
                                            <input
                                                type="text"
                                                name="user_lastname"
                                                id="lastname"
                                                placeholder="Apellido"
                                                className="formbold-form-input"
                                            />
                                        </div>
                                    </div>
                                    <div className="formbold-input-flex">
                                        <div>
                                            <label htmlFor="email" className="formbold-form-label"autoComplete="email"><strong>Email</strong>  </label>
                                            <input
                                                type="email"
                                                name="user_email"
                                                id="email"
                                                placeholder="aguascristalinas@mail.com"
                                                className="formbold-form-input"
                                            />
                                        </div>
                                        <div>
                                            <label htmlFor="phone" className="formbold-form-label"autoComplete="tel-national"> <strong>Teléfono</strong> </label>
                                            <input
                                                type="text"
                                                name="user_phone"
                                                id="phone"
                                                placeholder="2926413836"
                                                className="formbold-form-input"
                                            />
                                        </div>
                                    </div>

                                    <div className="formbold-input-radio-wrapper">
                                    <span htmlFor="jobtitle" className="formbold-form-label"> <strong>¿Qué estás buscando?</strong> </span>
                                    <div className='row w-100 formbold-radio'>
                                        <div className='col-6'>
                                            <label className='formbold-radio-label'>
                                                <input type='checkbox' id='asesoramiento' name='asesoramiento' value='asesoramiento'/>
                                            Asesoramiento piletas</label>
                                        </div>
                                        <div className='col-6'>
                                            <label className='formbold-radio-label'>
                                                <input type='checkbox' id='info' name='info' value='info'/>
                                            Información</label>
                                        </div>
                                        <div className='col-6'>
                                            <label className='formbold-radio-label'>
                                                <input type='checkbox' id='presupuesto' name='presupuesto' value='presupuesto'/>
                                            Presupuesto riego</label>
                                        </div>
                                        <div className='col-6'>
                                            <label className='formbold-radio-label'>
                                                <input type='checkbox' id='otro' name='otro' value='otro'/>
                                            Otro</label>
                                        </div>
                                    </div>
                                    <div><br></br>
                                        <label htmlFor="message" className="formbold-form-label"> <strong>Mensaje </strong></label>
                                        <textarea rows="3" name="message" id="message" placeholder="Escribinos tu mensaje"
                                            className="formbold-form-input"></textarea>
                                    </div>
                                   <button className='formbold-btn'type="submit" value="Enviar"> Enviar  </button> 
                                   </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>  


        </>
    )
}

export default Home