import React, { useEffect, useState } from 'react'
import instantaneo from './Img/productos/CloroInstantaneo.jpg';
import granulado from './Img/productos/CloroGranulado.jpg';
import pastillas from './Img/productos/pastillas.jpg';
import natabio from './Img/productos/natabio.jpg';
import clarificador from './Img/productos/clarificador.jpg';
import alguicida from './Img/productos/alguicida.jpg';
import hongos from './Img/productos/tecnocorHongos.jpg';
import insectos from './Img/productos/insectos.jpg';
import lona from './Img/productos/Lona.jpg';
import MobileMediumPageProductos from './MobileMediumPageProductos';


function Productos() {
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Actualiza el estado cuando cambie el ancho de la ventana
        const handleResize = () => {
            const newWidth = window.innerWidth;

            setWindowWidth(newWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
      <div>
      {windowWidth <= 991 ? (
          <MobileMediumPageProductos />
      ) : (
          <>
              <br /><br /><br /><br /><br /><br />
              <div className='text-center '>
        <h2 className='textoproductos'>Tenemos todo lo necesario para mantener en perfectas condiciones tu piscina</h2>
        <a href='calculoproductos' className='custom-link h2'>¿Cuánto producto necesito para mi pileta? ¡Descubrilo!</a>
      </div>
              <div id="carouselExampleControls" className="carousel carousel-dark slide w-100" data-bs-ride="carousel">
                  <div className="carousel-inner">
                      <div className="carousel-item active">
                          <div className="row card-wrapper d-flex justify-content-around ps-5 pe-5 w-100 mx-auto">
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={instantaneo} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Cloro instantáneo</h5>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={granulado} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Cloro granulado</h5>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={pastillas} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Pastillas multiacción</h5>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={natabio} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Natabio</h5>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="carousel-item">
                          <div className="row card-wrapper d-flex justify-content-around ps-5 pe-5 w-100 mx-auto">
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={clarificador} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Clarificador</h5>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={alguicida} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Alguicida</h5>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={hongos} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Hongos</h5>
                                      </div>
                                  </div>
                              </div>
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={insectos} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Insectos</h5>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="carousel-item">
                          <div className="row card-wrapper d-flex justify-content-around ps-5 pe-5 w-100 mx-auto">
                              <div className="col-lg-3">
                                  <div className="card d-flex flex-column" style={{ minHeight: '225px' }}>
                                      <img src={lona} className="card-img-top" alt="producto" />
                                      <div className="card-body flex-grow-1 d-flex flex-column justify-content-end">
                                          <h5 className="card-title text-center">Piletas de lona</h5>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <button className="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
                      <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Previous</span>
                  </button>
                  <button className="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
                      <span className="carousel-control-next-icon" aria-hidden="true"></span>
                      <span className="visually-hidden">Next</span>
                  </button>
              </div>
              <br/><br/>
          </>
      )}
  </div>
);
}

export default Productos;