import React from 'react'
import riego1 from './Img/481-767/Riego/riego1-481-767.jpg';
import riego2 from './Img/481-767/Riego/riego2-480.jpg';
import riego3 from './Img/481-767/Riego/riego3-481-767.jpg';

function MobilePageRiegos() {
    return (
        <><br /><br /><br /> 
            <div className='overflow'>   
                <div className="card mb-3">
                    <div className="row ">
                        <div className="col-md-3 w-100">
                            <div id="carouselRiegosMobile" className="carousel slide" data-bs-ride="carousel">
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <img src={riego3} className="img-fluid" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={riego2} className="d-block w-100" alt="..." />
                                    </div>
                                    <div className="carousel-item">
                                        <img src={riego1} className="d-block w-100" alt="..." />
                                    </div>
                                </div>
                                <button className="carousel-control-prev" type="button" data-bs-target="#carouselRiegosMobile" data-bs-slide="prev">
                                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Previous</span>
                                </button>
                                <button className="carousel-control-next" type="button" data-bs-target="#carouselRiegosMobile" data-bs-slide="next">
                                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                                    <span className="visually-hidden">Next</span>
                                </button>
                            </div>

                        </div>
                        <div className="col-md-9">
                            <div className="card-body ">
                                <p className="card-text descripcionmobile ">
                                    Instalamos riegos residenciales y deportivos, así como proyectos a gran escala para el riego de plantaciones. <br />
                                    Nuestros sistemas de riegos logran un máximo de aprovechabilidad del área para una mayor economía de agua y energía.<br />

                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div></>
    )
}

export default MobilePageRiegos