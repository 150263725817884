import React, { useEffect, useRef, useState } from 'react'
import MobilePageClimatizacion from './MobilePageClimatizacion';

function ClimatizacionSolar({ onBackgroundImageCheck }) {
    const sectionRef = useRef(null);
    useEffect(() => {
        const sectionElement = sectionRef.current;
        if (sectionElement) {
            const style = window.getComputedStyle(sectionElement);
            const hasBackgroundImage = style.backgroundImage !== 'none';
            onBackgroundImageCheck(hasBackgroundImage);
        }
    }, [onBackgroundImageCheck]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Actualiza el estado cuando cambie el ancho de la ventana
        const handleResize = () => {
            const newWidth = window.innerWidth;

            setWindowWidth(newWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <div>
          {windowWidth <= 480 ? (
            <MobilePageClimatizacion /> // Muestra la página para dispositivos móviles si es menor o igual a 480px
          ) : (
            // Muestra la página regular aquí
            <div>
               <div id='carouselsolar' className='carousel slide w-100 carousel-fade' data-bs-ride="carousel">
                <div className='carousel-inner'>
                    <div className='carousel-item active w-100'>
                        <div className='d-flex w-100' id='imgsolar' ref={sectionRef}>
                            <div className='row align-self-center w-100 text-start'id='alineardescripcion'>
                                 
                                <div className='col-lg-8 col-md-10 col-sm-12 col-xs-12'>
                                    <h1 id='titulosolar'>Climatización solar</h1>
                                    <p className='lead' id='descripcionsolar'>
                                        Es la forma mas sostenible y eficiente de mantener la temperatura ideal en tu pileta con nuestro sistema de climatización solar.
                                        Nuestro sistema utiliza paneles solares estratégicamente ubicados para absorber el calor del sol y transferirlo al agua de tu piscina.
                                        No solo reducirás significativamente los costos de calefacción, sino que también estarás contribuyendo al cuidado del medio ambiente al utilizar energía limpia y renovable.
                                    </p>
                                </div>
                                <div className='col-lg-4 col-md-2'></div>
                            </div>
                        </div>
                    </div>
                    <div className='carousel-item'>
                        <div className='d-flex w-100' id='imgsolar2' ref={sectionRef}>
                            <div className='row align-self-center w-100 text-start' id='alineardescripcion'>

                                <div className='col-lg-8 col-md-10 col-sm-12 col-xs-12'>
                                    <h1 id='titulosolar'>Climatización solar</h1>
                                    <p className='lead' id='descripcionsolar'>
                                    Es la forma mas sostenible y eficiente de mantener la temperatura ideal en tu pileta con nuestro sistema de climatización solar.
                                        Nuestro sistema utiliza paneles solares estratégicamente ubicados para absorber el calor del sol y transferirlo al agua de tu piscina.
                                        No solo reducirás significativamente los costos de calefacción, sino que también estarás contribuyendo al cuidado del medio ambiente al utilizar energía limpia y renovable.
                                    </p>
                                </div>
                                <div className='col-lg-4 col-md-2'></div>
                            </div>
                        </div>
                    </div>

                </div>
                {/*BOTONES */}
                <button className="carousel-control-prev" type="button" data-bs-target="#carouselsolar" data-bs-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Previous</span>
                </button>
                <button className="carousel-control-next" type="button" data-bs-target="#carouselsolar" data-bs-slide="next">
                    <span className="carousel-control-next-icon" aria-hidden="true"></span>
                    <span className="visually-hidden">Next</span>
                </button>

            </div>
              {/* Coloca el contenido de tu página regular aquí */}
            </div>
          )}
        </div>
      );
    }
    


   


export default ClimatizacionSolar