import React from 'react'
import circon from './Img/pequeñas/Circon.png';
import circonIlustracion from './Img/pequeñas/Circon Ilustracion.png';
import jaspe from './Img/pequeñas/Jaspe.png';
import jaspeIlustracion from './Img/pequeñas/Jaspe Ilustracion.png';
import topacio from './Img/pequeñas/Topacio.png';
import topacioIlustracion from './Img/pequeñas/Topacio Ilustracion.png';
import cuarzo from './Img/pequeñas/Cuarzo.png';
import cuarzoIlustracion from './Img/pequeñas/Cuarzo Ilustracion.png';
import tanzanita from './Img/pequeñas/Tanzanita.png';
import tanzanitaIlustracion from './Img/pequeñas/Tanzanita Ilustracion.png';
import ambar from './Img/pequeñas/Ambar.png';
import ambarIlustracion from './Img/pequeñas/Ambar Ilustracion.png';

function PiletasPequenas() {
  return (
    <>
    <br></br><br></br><br></br>

    <div className='mt-5 w-100'>
      <div className='row m-1'>
        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Circón</h4>
            <img src={circon} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 5,5 m</div>
                <div className='card border-0 ps-2'>Ancho: 2,8 m</div>
                <div className='card ps-2'>Profundidad: 1,3 a 1,4 m</div>
                <div className='card border-0 ps-2'>Volumen: 21.000 lt</div>
              </div>
            </div>
            <br />
            <img src={circonIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>

        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Jaspe</h4>
            <img src={jaspe} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 5,45 m</div>
                <div className='card border-0 ps-2'>Ancho: 3,0 m</div>
                <div className='card ps-2'>Profundidad: 1,3 m</div>
                <div className='card border-0 ps-2'>Volumen: 18.000 lt</div>
              </div>
            </div>
            <br />
            <img src={jaspeIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>

        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Topacio</h4>
            <img src={topacio} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 3,35 m</div>
                <div className='card border-0 ps-2'>Ancho: 2,0 m</div>
                <div className='card ps-2'>Profundidad: 0,9 m</div>
                <div className='card border-0 ps-2'>Volumen: 6.000 lt</div>
              </div>
            </div>
            <br />
            <img src={topacioIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>


      </div>
    </div>
    
    <div className='mt-5 w-100'>
      <div className='row m-1'>
        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Tanzanita</h4>
            <img src={tanzanita} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 4,55 m</div>
                <div className='card border-0 ps-2'>Ancho: 2,55 m</div>
                <div className='card ps-2'>Profundidad: 1,1 m</div>
                <div className='card border-0 ps-2'>Volumen: 12.200 lt</div>
              </div>
            </div>
            <br />
            <img src={tanzanitaIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>

        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Cuarzo</h4>
            <img src={cuarzo} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 4,10 m</div>
                <div className='card border-0 ps-2'>Ancho: 2,30 m</div>
                <div className='card ps-2'>Profundidad: 1,05 m</div>
                <div className='card border-0 ps-2'>Volumen: 9.900 lt</div>
              </div>
            </div>
            <br />
            <img src={cuarzoIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>

        <div className='col-md-4 mb-4'>
          <div className='card mt-3'id='border'>
            <h4 className='card-title text-center pt-2'>Ambar</h4>
            <img src={ambar} className='card-img-top px-3'alt='Circón'/>
            <div className='card-body'>
              <div className='card p-1' id='border'>
                <h6 className='ps-1 fw-bold'>Medidas</h6>
                <div className='card ps-2'>Largo: 5,60 m</div>
                <div className='card border-0 ps-2'>Ancho: 2,80 m</div>
                <div className='card ps-2'>Profundidad: 1,25 a 1,50 m</div>
                <div className='card border-0 ps-2'>Volumen: 18.000 lt</div>
              </div>
            </div>
            <br />
            <img src={ambarIlustracion} className='card-img-top' alt='Circón' />
          </div>
        </div>


      </div>
    </div>
    </>
  )
}

export default PiletasPequenas