import React, { useEffect, useRef, useState } from 'react'
import MobilePageCobertor from './MobilePageCobertor';

function Cobertores({ onBackgroundImageCheck }) {
    const sectionRef = useRef(null);
    useEffect(() => {
        const sectionElement = sectionRef.current;
        if (sectionElement) {
            const style = window.getComputedStyle(sectionElement);
            const hasBackgroundImage = style.backgroundImage !== 'none';
            onBackgroundImageCheck(hasBackgroundImage);
        }
    }, [onBackgroundImageCheck]);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        // Actualiza el estado cuando cambie el ancho de la ventana
        const handleResize = () => {
            const newWidth = window.innerWidth;

            setWindowWidth(newWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div>
            {windowWidth <= 480 ? (
                <MobilePageCobertor /> // Muestra la página para dispositivos móviles si es menor o igual a 480px
            ) : (
                // Muestra la página regular aquí
                <div>
                    <div id="carouselcobertores" className="carousel slide w-100 carousel-fade" data-bs-ride="carousel">
                        <div className='carousel-inner'>
                            <div className='carousel-item active'>
                                <div className="d-flex w-100 d-block" id="img2" ref={sectionRef} >
                                    <div className="row align-self-center  w-100 " id='alineardescripcion'>
                                        <div className="col-md-7">
                                            <h1 id="titulocobertor">Cobertores</h1>
                                            <p className="lead" id="descripcioncobertor">
                                                Los cobertores para piscinas son una solución práctica para proteger y mantener tu piscina en óptimas condiciones durante todo el año.
                                                Además de su función protectora, también brindan seguridad.<br></br> Al cubrir completamente la piscina, ayudan a prevenir accidentes,
                                                especialmente para hogares con niños pequeños o mascotas.
                                            </p>
                                        </div>
                                        <div className="col-md-5"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='carousel-item'>
                                <div className="d-flex" id="img2-1" ref={sectionRef} >
                                    <div className="row  align-self-center  w-100 " id='alineardescripcion' >
                                        <div className="col-md-7 ">
                                            <h1 id="titulocobertor">Cobertores</h1>
                                            <p className="lead" id="descripcioncobertor">
                                                Los cobertores para piscinas son una solución práctica para proteger y mantener tu piscina en óptimas condiciones durante todo el año.
                                                Además de su función protectora, también brindan seguridad.<br></br> Al cubrir completamente la piscina, ayudan a prevenir accidentes,
                                                especialmente para hogares con niños pequeños o mascotas.
                                            </p>
                                        </div>
                                        <div className="col-md-5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*BOTONES */}
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselcobertores" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselcobertores" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>

                    <div id="carouselcerramientos" className="carousel slide w-100 carousel-fade" data-bs-ride="carousel">
                        <div className='carousel-inner'>
                            <div className='carousel-item active'>
                                <div className="d-flex w-100 d-block" id="img3-1" ref={sectionRef} >
                                    <div className="row align-self-center  w-100 " id='alineardescripcion' >
                                        <div className="col-md-7">
                                            <h1 id="titulocobertor">Cerramientos</h1>
                                            <p className="lead" id="descripcioncobertor">
                                                Descubre nuestra solución de cerramientos para piscinas.
                                                Con nuestro sistema de seguridad, podrás proteger tu piscina de manera efectiva y brindar tranquilidad a tu familia.
                                                Nuestros cercos son duraderos, de fácil instalación y cumplen con los estándares de seguridad más exigentes. <br />
                                                ¡Disfruta de tu piscina con total paz mental gracias a nuestros cercos perimetrales!
                                            </p>
                                        </div>
                                        <div className="col-md-5"></div>
                                    </div>
                                </div>
                            </div>
                            <div className='carousel-item'>
                                <div className="d-flex" id="img3" ref={sectionRef} >
                                    <div className="row  align-self-center  w-100 " id='alineardescripcion' >
                                        <div className="col-md-7 ">
                                            <h1 id="titulocobertor">Cerramientos</h1>
                                            <p className="lead" id="descripcioncobertor">
                                                Descubre nuestra solución de cerramientos para piscinas.
                                                Con nuestro sistema de seguridad, podrás proteger tu piscina de manera efectiva y brindar tranquilidad a tu familia.
                                                Nuestros cercos son duraderos, de fácil instalación y cumplen con los estándares de seguridad más exigentes. <br />
                                                ¡Disfruta de tu piscina con total paz mental gracias a nuestros cercos perimetrales!
                                            </p>
                                        </div>
                                        <div className="col-md-5"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/*BOTONES
    
    */}
                        <button className="carousel-control-prev" type="button" data-bs-target="#carouselcerramientos" data-bs-slide="prev">
                            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Previous</span>
                        </button>
                        <button className="carousel-control-next" type="button" data-bs-target="#carouselcerramientos" data-bs-slide="next">
                            <span className="carousel-control-next-icon" aria-hidden="true"></span>
                            <span className="visually-hidden">Next</span>
                        </button>
                    </div>
              {/* Coloca el contenido de tu página regular aquí */}
              </div>
              )}
            </div>
          );
        }
        
        
     

export default Cobertores